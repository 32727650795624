import { useEffect, useRef, useState } from "react";
import { ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import { STATE } from "../../constants";
import axios from "axios";
import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
import { RootState } from "../../redux/store";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { TieredMenu } from "primereact/tieredmenu";
import { MultiSelect } from "primereact/multiselect";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { toast } from "react-toastify";
import { OverlayPanel } from "primereact/overlaypanel";

const ReviewLosses=()=> 
{
    const disbursementData = {
        borrower_name: "",
        lan_no: "",
        // pan_no: "",
        borrower_contact_person_name: "",
        loan_amount: "",
        borrower_pincode: "",
        logincodelist_id: "",
        financer_name: "",
        // product: "",
        disbursement_date: new Date(),
        borrower_contact_person_number: "",
        dsa_name_error:null,
        borrower_name_error: null,
        lan_no_error: null,
        loan_amount_error: null,
        pincode_error: null,
        financer_name_error: null,
        product_error: null,
        // pan_no_error: null,
        contact_person_name_error: null,
        month_of_disbursement_error: null,
        mobile_error: null,
        connector_id: null,
        user_id: '',
        is_connector: false,
        Userpayout: 0,
        connector_rate: null,
        connector_error: null,
        adjustment_amount: 0,
        reason: '',
        reason_error:null,
        state_error: null,
        city_error: null,
        state: "",
        city: "",
      };
const [loader, setLoader] = useState(true);
const [formData, setFormData] = useState<any>(disbursementData);
const [disbursementDetail, setDisbursementDetail] = useState([]);
const [state, setState] = useState(STATE.DEFAULT_STATE);
const [bankData, setBankData] = useState<any>({});
const userData = useSelector(
    (state: RootState) => state?.authUser?.userData?.user
  );
  const [advanceRequests, setAdvanceRequests] = useState(null);
  const [advanceStatuses, setAdvanceStatuses] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [financers, setFinancers] = useState(null);
  const [dsaName, setDsaNames] = useState(null);
  const [paymentStatuses, setPaymentStatuses] = useState(null);
  const [products, setProducts] = useState(null);
  const [paginator, setPaginator] = useState(true);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    pagination:'',
    search: "",
    filters: {
      Financer: { value: null, matchMode: FilterMatchMode.IN },
      DsaName: { value: null, matchMode: FilterMatchMode.IN },
      Product: { value: null, matchMode: FilterMatchMode.IN },
      dismonth: { value: null, matchMode: FilterMatchMode.IN },
      Paymentstatus: { value: null, matchMode: FilterMatchMode.IN },
      Advancerequest: { value: null, matchMode: FilterMatchMode.IN },
      Advancestatus: { value: null, matchMode: FilterMatchMode.IN },
    },
  });
  const [selectedId, setSelectedId] = useState<string>("");
  const reasonData={
    reason:'',
    reason_error:null
  }
  const [reasonInfo,setReason]=useState(reasonData);
  const [reasonPopup,setReasonPopUp]=useState(false);
  const [permission, setPermission] = useState<any>({});
  let date=new Date().toDateString();
  const [newdate,setDate]=useState(moment(date).format('DD-MM-YY'))
  const op = useRef(null);
  const[exportType,setExportType]=useState('');
  useEffect(() => {
      const tempPermission = {
      [PERMISSION_TYPE.ADMIN]: userPermission(userData, PERMISSION_TYPE.ADMIN),
      [PERMISSION_TYPE.USER]: userPermission(userData, PERMISSION_TYPE.USER),
      [PERMISSION_TYPE.STAFF]: userPermission(userData, PERMISSION_TYPE.STAFF),
      [PERMISSION_TYPE.CONNECTOR]: userPermission(userData, PERMISSION_TYPE.CONNECTOR),
    };
    setPermission(tempPermission)

  }, [userData]);
  const getOptionsData = () => {
    setLoader(true);
    ApiGet(`options/disbursement`).then((res: any) => {
      const advanceRequests = convertToObjectArray(res.data.advance_request);
      const advanceStatuses = convertToObjectArray(res.data.advance_status);
      const financers = convertToObjectArray(res.data.financers);
      const paymentStatuses = convertToObjectArray(res.data.payment_status);
      const products = convertToObjectArray(res.data.products);
      const dsaName = convertToObjectArray(res.data.dsa_name);
      setAdvanceRequests(advanceRequests);
      setAdvanceStatuses(advanceStatuses);
      setFinancers(financers);
      setDsaNames(dsaName);
      setPaymentStatuses(paymentStatuses);
      setProducts(products);
    });
  };
  const convertToObjectArray = (obj) => {
    return Object.keys(obj).map((key) => ({
      label: obj[key]?.toString(),
      value: key,
    }));
  };

  const advanceRequestFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={advanceRequests}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const advanceStatusesFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={advanceStatuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const financersFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={financers}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };
  const dsaNameFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={dsaName}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const paymentStatusesFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={paymentStatuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const productsFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={products}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  
    const columns = [
      { id: "SRNO", label: "SR.NO." },
      { id: 'dsa_name', label: 'DSA Name' } ,
      { id: "borrower_name", label: "Borrower Name" },
      { id: "borrower_contact_person_name", label: "Contact Person" },
      { id: "loan_amount", label: "Loan Amount" },
      { id: "bank_name", label: "Financer" },
      { id: "product", label: "Product" },
      { id: "disbursement_date", label: "Dis. Month" },
      { id: "connector_name", label: "Connector Name" },
      { id: "bank_payout", label: "Bank Gross Payout" },
      { id: "gross_payout", label: "DSA Gross Payout" },
      { id: "connector_gross_payout", label: "Connector Gross Payout" },
      { id: "status", label: "Payment Status" },
      { id: "advance_request", label: "Advance Request" },
      { id: "advance_status", label: "Advance Status" },
      { id: "loss_amount", label: "Loss Amount" },
    ];

    useEffect(() => {
      Promise.all([
        getData(),
        // getConnector(),
        getOptionsData(),
      ])
        .then(() => {
          setTimeout(() => {
            setLoader(false);
          }, 250);
        })
        .catch((error) => {
          setLoader(false);
        });
    }, [lazyState,paginator]);
    const getData = () => {
      setLoader(true);
      let  modifiedLazyState ;
      if(paginator) {
        modifiedLazyState={  ...lazyState,
          limit: lazyState.rows,
          pagination: true,
        }}
        else{
          modifiedLazyState={  ...lazyState,
            limit: lazyState.rows,
            pagination: false,
          }
        }
        
      delete modifiedLazyState.rows;
      delete modifiedLazyState["multiSortMeta"];
      delete modifiedLazyState["sortField"];
      delete modifiedLazyState["sortOrder"];
  
      // Function to replace empty arrays in the "value" field with null
      const replaceEmptyValueArrays = (obj) => {
        for (const key in obj) {
          if (
            key === "value" &&
            Array.isArray(obj[key]) &&
            obj[key].length === 0
          ) {
            obj[key] = null;
          } else if (typeof obj[key] === "object") {
            replaceEmptyValueArrays(obj[key]);
          }
        }
        return obj;
      };
      // Call the function to replace empty arrays in the "value" field with null
      const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
      finalModifiedLazyState.page++;
      ApiPost(`disbursement/get-loss-disbursement`, finalModifiedLazyState)
        .then((res: any) => {
          setTotalRecords(Number(res.data.count.count));
            let finalData = res.data.result.map((item: any, index: number) => {
              return {
                SRNO: index + 1,
                id: item._id,
                DsaName: item.dsa_name ?? "-",
                BorrowerName: item.borrower_name ?? "-",
                Contact_Person_Name:
                  item.borrower_contact_person_name == ""
                    ? "-"
                    : item.borrower_contact_person_name,
                Loanamount: item.loan_amount ?? "-",
                Financer: item.bank_name ?? "-",
                Product: item?.product ?? "-",
                dismonth: item.disbursement_date ?? "-",
                Connectorname: item?.connector_name ?? "-",
                Grosspayout: item?.gross_payout ?? "0",
                Advancegross: item?.advance_gross,
                Tdspayout: item?.tds_amount ?? "0",
                Gstpayout: item?.gst_amount ?? "0",
                Userpayout: item?.rate ?? "0",
                Netpayout: item?.net_amount_payable ?? "0",
                Advancerequest: item?.advance_request ?? "-",
                Advancestatus: item?.advance_status ?? "-",
                Paymentstatus: item?.status,
                city: item?.city,
                state: item?.state,
                borrower_pincode: item?.borrower_pincode,
                borrower_contact_person_number:
                  item?.borrower_contact_person_number,
                logincodelist_id: item?.logincodelist_id,
                connector_id: item?.connector_id,
                connector_rate: item?.connector_rate,
                is_connector: item?.is_connector,
                lan_no: item?.lan_no,
                adjustment_amount: item?.adjustment_amount,
                // disbursement_date:item?.disbursement_date,
                loss_amount:item.loss_amount,
                reason: item?.reason,
                connector_gross_payout:item.connector_gross_payout,
                connector_gst_amount:item.connector_gst_amount,
                connector_net_amount_payable:item.connector_net_amount_payable,
                connector_tds_amount:item.connector_tds_amount,
                bank_payout:item.bank_payout
              };
            });
            setDisbursementDetail(finalData);
            setState({
              ...state,
              totalItems: res.data.count.count,
            });
            if(!paginator)
              {
                if(exportType == 'pdf'){
            const data=res.data.result;
            const doc = new jsPDF("landscape", "mm", "a2");
      
            let width = doc.internal.pageSize.width;
            let height = doc.internal.pageSize.height;
        
            const img = new Image();
            img.src = "assets/logo.png";
            img.onload = function () {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.globalAlpha = 0.2;
  
             ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              const watermarkBase64 = canvas.toDataURL("image/png");
  
              const maxWidth = width * 0.5;
              const maxHeight = height * 0.5; 
              let newWidth = img.width;
              let newHeight = img.height;
  
              if (newWidth > maxWidth || newHeight > maxHeight) {
                const widthRatio = maxWidth / newWidth;
                const heightRatio = maxHeight / newHeight;
                const scale = Math.min(widthRatio, heightRatio);
        
                newWidth = newWidth * scale;
                newHeight = newHeight * scale;
            }
        
            // Calculate the center position
            let xPos = (width - newWidth) / 2;
            let yPos = (height - newHeight) / 2;
              let column=columns.filter((col)=>{
                 if(col != null)
                  return col;
              })
              const title = "Loss List";
            doc.setFontSize(18);
            doc.setFont("helvetica");
            const textWidth = doc.getTextDimensions(title).w;
            doc.text(title, (width - textWidth) / 2, 10);
              // doc.addImage(img, 'PNG', width / 2 - 100, height / 2 - 100, 200, 200, undefined, 'SLOW');
              autoTable(doc, {
                head: [column.map((col) =>        
                       col.label
                    )],
                body: data.map((product, index) =>
                  column.map((col) => {                  
                    if ( col.id === "SRNO" ) {
                      return index + 1;
                    } else if(col != null) {
                      return product[col.id];
                    }
                  })
                ),
                styles: {
                  fontSize: 10,
                  cellPadding: 3,
                  textColor: [0, 0, 0],
                },
                headStyles: {
                  fillColor: [52, 152, 219],
                  textColor: [255, 255, 255],
                },
                margin: { top: 15 },
                theme: "grid",
                didDrawPage: function (data) {
                  doc.addImage(
                    watermarkBase64,
                    "PNG",
                    xPos, yPos, newWidth, newHeight
                  );
                },
              });
              doc.save(`Loss_${newdate}.pdf`);
              
            };
            setPaginator(true);
            setLoader(false);
          }             
  else{

    const csvRows = [];
    const data = res.data.result;
    const modifiedData = data.map((product, index) => {
      // Create a row with custom logic for "SRNO"
      const row = columns.map(col => {

        if (col.id === "SRNO") {
          return index + 1; // Add serial number
        }
         else {
          return product[col.id]; // Add product data
        }
      });
  
      return row;
    });
    csvRows.push(columns.map(col => col.label));

    modifiedData.forEach(row => {
      csvRows.push(
        row
      );
    });

const csvContent = 'data:text/csv;charset=utf-8,' + csvRows.join('\n');
const encodedUri = encodeURI(csvContent);
const link = document.createElement('a');
link.setAttribute('href', encodedUri);
link.setAttribute('download', `Loss_${newdate}.csv`);
document.body.appendChild(link); // Required for FF
link.click();
document.body.removeChild(link);
    setPaginator(true)  ;               
    setLoader(false);
  
  }}
 }
)
        .catch((error) => {
          setDisbursementDetail([]);
        });

    };
    
    const dt = useRef(null);
  
    const accpetLose=()=>{
       let error={...reasonInfo}

      if(reasonInfo.reason === ""){
        error={...reasonInfo , reason_error:'Please provide Reason'}
      }
      setReason(error)
      const data={
        loss_approved: true,
        reason: reasonInfo.reason
      };
      if(error.reason_error === null ){
        ApiPut(`disbursement/approve-loss?id=${selectedId}`,data)
        .then((res: any) => {
          setSelectedId('')
          setReasonPopUp(false)
          setReason(reasonData)
          getData()
          toast.success(res.data, {
              position: "top-right",
              theme: "colored"
            })
        })
      }
      
    }
    const onPage = (event) => {
      let filter = { ...lazyState };
      filter = event;
      filter["search"] = lazyState.search;
      setlazyState(filter);
    };
  
    const onFilter = (event) => {
      event["first"] = 0;
      event["page"] = 0;
      const dismonthValue = event.filters?.dismonth?.value;
  
      if (
        dismonthValue &&
        dismonthValue.month === null &&
        dismonthValue.year === null
      ) {
        event.filters.dismonth.value = null;
      }
  
      setlazyState(event);
    };
    const targetRef = useRef();
  
       const exportPdf = () => {
      setPaginator(false);
      getData();   
    
      setExportType('pdf');
         
  };
  
  const exportCSV = () => {
    setExportType('csv');
    setPaginator(false);
    getData();   

  };
    const handleInputChange = (event) => {
      setSearchValue(event.target.value);
    };

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        onGlobalFilterChange(searchValue);
      }
    };
  
    const handleSearchClick = () => {
      onGlobalFilterChange(searchValue);
    };
  
    const renderHeader = () => {
      return (
        <div className="flex justify-end justify-space-between">
          <div>
            {/* <Button
              type="button"
              icon="pi pi-file-pdf"
              rounded
              onClick={exportPdf}
              data-pr-tooltip="PDF"
            /> */}
            <Button label="Export" icon="pi pi-upload" className="p-button" onClick={(e) => op.current.toggle(e)}>
        <OverlayPanel ref={op}>
                <option style={{ margin: '10px 0',cursor: 'default' }} value='bronze' onClick={exportPdf}>Pdf </option>
                    { (permission[PERMISSION_TYPE.ADMIN] ) && (<option style={{ margin: '10px 0',cursor: 'default' }} value='silver' onClick={() => exportCSV()}>Csv</option>
                                        )}
                                        </OverlayPanel>
        </Button>
          </div>
          <div>
            <span>
              <InputText
                className="mr-2 ml-3"
                value={searchValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Keyword Search"
              />
              <Button icon="pi pi-search" onClick={handleSearchClick} />
            </span>
          </div>
        </div>
      );
    };  
    const header = renderHeader();
  
    const onGlobalFilterChange = (e) => {
      const value = e;
      let filter = { ...lazyState };
  
      filter["search"] = value;
  
      setlazyState(filter);
    };
  
    const DismonthFilterTemplate = (options) => {
      const months = [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
      ];
  
      const years=[];
      let currentYear;
      let currentMonth = new Date().getMonth();
                if(currentMonth <= 6){
                   currentYear = new Date().getFullYear();
                }
                else{
                   currentYear = new Date().getFullYear()+1;
                }  
                for (let i = currentYear; i >= 2018; i--) {
                  years.push({ label: i.toString(), value: i });
                }  
  
      const onApplyFilter = () => {
        options.filterApplyCallback({ month: selectedMonth, year: selectedYear });
      };
  
      const onClearFilter = () => {
        setSelectedMonth(null);
        setSelectedYear(null);
        options.filterApplyCallback({ month: null, year: null });
      };
    const isBothSelected = selectedMonth && selectedYear;

    return (
      <div style={{ minWidth: "14rem" }}>
        <div className="mb-2">
          <Dropdown
            optionLabel="label"
            optionValue="value"
            value={selectedMonth}
            options={months}
            onChange={(e) => setSelectedMonth(e.value)}
            placeholder="Select Month"
            className="p-column-filter"
            filter
          />
        </div>
        <div className="mb-2">
          <Dropdown
            optionLabel="label"
            optionValue="value"
            value={selectedYear}
            options={years}
            onChange={(e) => setSelectedYear(e.value)}
            placeholder="Select Year"
            className="p-column-filter"
            filter
          />
        </div>
        <div className="p-column-filter-buttonbar" style={{ padding: "0" }}>
          <Button
            label="Clear"
            onClick={onClearFilter}
            className="p-button p-component p-button-outlined p-button-sm"
          />
          <Button
            label="Apply"
            onClick={onApplyFilter}
            className="p-button p-component p-button-sm"
            disabled={!isBothSelected}
          />
        </div>
      </div>
    );
  };  
    
  const handleMenuItemClick = (itemLabel) => {
    if (itemLabel === "Accept Lose") {
      setSelectedId(selectedRowData.current.id);
      setReasonPopUp(true)
    }
    
  };
  const menuRef = useRef(null);
  const selectedRowData = useRef(null);
  const handleEllipsisClick = (event, rowData) => {
    event.stopPropagation(); // Prevent event from bubbling up
    selectedRowData.current = rowData;
    if (menuRef.current) {
      menuRef.current.toggle(event);
    }
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <img
          src="../assets/menu-cirlce.svg"
          alt="menu-icon"
          style={{ cursor: "pointer" }}
          onClick={(e) => handleEllipsisClick(e, rowData)}
        />
        <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
      </div>
    );
  };

  const generateMenuItems = () => {
    const menuItems = [];
 
    menuItems.push({
      label: "Accept Lose",
      command: () => handleMenuItemClick("Accept Lose"),

    });
    
    return menuItems;
  };
  
    return (
<div className="overflow-x-hidden">
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
        <div className="p-4 sm:p-9 space-y-5">
            {/* START::DASHBOARD HEADER */}
            <div className="flex flex-wrap gap-5 items-center justify-between">
              <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                <h1 className="text-[22px] text-light-gray font-semibold">
                  Review Losses Data
                </h1>
                </div>
              </div>
            <DataTable
              ref={dt}
              value={disbursementDetail}
              lazy
              dataKey="id"
              showGridlines
              paginator={paginator}
              first={lazyState.first}
              filters={lazyState.filters}
              onFilter={onFilter}
              rows={lazyState.rows}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              totalRecords={totalRecords}
              onPage={onPage}
              loading={loader}
              size={"small"}
              scrollable
              scrollHeight="600px"
              className="custom-datatable"
              header={header}
              emptyMessage="No records found."
              
            >
              <Column field="SRNO" header="SR.NO." />
              
                <Column
                  field="DsaName"
                  header="DSA Name"
                  showFilterMatchModes={false}
                  filterMenuStyle={{ minWidth: "14rem" }}
                  filter
                  filterElement={dsaNameFilterTemplate}
                />
              <Column field="BorrowerName" header="Borrower Name" />
              <Column field="Contact_Person_Name" header="Contact Person" />
              <Column field="Loanamount" header="Loan Amount" />
              <Column
                field="Financer"
                header="Financer"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={financersFilterTemplate}
              />
              <Column
                field="Product"
                header="Product"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={productsFilterTemplate}
              />
              <Column
                field="dismonth"
                header="Disbursement Month"
                showFilterMatchModes={false}
                showApplyButton={false}
                showClearButton={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={DismonthFilterTemplate}
              />
              <Column field="Connectorname" header="Connector Name" />
              <Column field="bank_payout" header="Bank Gross Payout" />
              <Column field="Grosspayout" header="DSA Gross Payout" />
              <Column field="connector_gross_payout" header="Connector Gross Payout" />             
              <Column
                field="Paymentstatus"
                header="Payment Status"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={paymentStatusesFilterTemplate}
              />
              <Column
                field="Advancerequest"
                header="Advance Request"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={advanceRequestFilterTemplate}
              />
              <Column
                field="Advancestatus"
                header="Advance Status"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={advanceStatusesFilterTemplate}
              />
              <Column field="loss_amount"
                header="Loss Amount"></Column>
              {/* {permission[PERMISSION_TYPE.ADMIN] ? ( */}
              <Column
                field="Action"
                header="Action"
                body={actionBodyTemplate}
              />
              {/* ) : null} */}
            </DataTable>
          </div>
          <div
            className={`tw-modal ${
              reasonPopup ? "flex" : "hidden"
            } items-start `}
          >
            <div className="tw-modal-content ">
              <div className="tw-modal-title">
                <div className="text-2xl font-bold">Set Reason For Acceptance of lose</div>
                <button onClick={() => {setReasonPopUp(false)
                  setReason(reasonData)}
                }>
                  <img src="assets/close-outline.svg" alt="Close" />
                </button>
              </div>
              <label
                htmlFor="dropdown"
                className="block mb-2 text-sm font-medium"
              >
                {/* Level: */}
              </label>
              <div className="tw-modal-body">
                <div className="space-y-1">
                <label htmlFor="reason" className={`input-label ${
                        reasonInfo.reason_error ? "text-red-500" : ""
                      }`}>
                        Enter Reason :
                      </label>
                      <div id="reason" className={`input-wrap ${
                        reasonInfo.reason_error ? "border-red-500" : ""
                      }`}>
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="reason"
                          value={reasonInfo.reason}
                          onChange={(e: any) => {
                           setReason({...reasonInfo , reason:e.target.value, reason_error:null})
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Reason"
                        />
                      </div>
                  <p
                    id="level-error"
                    className="text-red-500 text-sm"
                  >
                    {reasonInfo.reason_error}
                  </p>
                </div>
              </div>
              

              <div className="tw-modal-footer">
                <Button
                  icon="pi pi-check"
                  // raised
                  severity="info"
                  onClick={() => {
                    accpetLose()
                  }}
                >
                  <span style={{ marginLeft: "8px" }}> Save</span>
                </Button>
                <Button
                  icon="pi pi-times"
                  raised
                  severity="danger"
                  onClick={() => {
                   setReasonPopUp(false)
                   setReason(reasonData)

                  }}
                >
                  {" "}
                  <span style={{ marginLeft: "8px" }}> Cancel</span>
                </Button>
              </div>
            </div>
          </div>
        </>
    )}
    </div>)
}
    
export default ReviewLosses;