import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import STORAGEKEY from "../../config/APP/app.config";
import { Logout_Auth } from "../../redux/reducers/authReducer";
import { RootState } from "../../redux/store";
import { PERMISSION_TYPE, userPermission } from "./../../helper/permission";
import { ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Button } from "primereact/button";
import { toast } from "react-toastify";

function Sidebar(props: any) {
  const { sidebarToggle, setSidebarToggle } = props;
  const [activeTab, setActiveTab] = useState("re-submitted");
  const location = useLocation();
  const userData = useSelector(
    (state: RootState) => state?.authUser?.userData?.user
  );
  const [permission, setPermission] = useState<any>({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [managername, setManagername] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [managerPhone, setManagerPhone] = useState("");
  const [type,setType]=useState(userData?.relationship_manager == null ? 'bp' : 'rm' );

  const getManagerName = async (id: any) => {
    await ApiGet(`user/get-manager-name?id=${id}&type=${type}`).then((res: any) => {
      let temp = res.data.data;
      if (res.data.data != "None") {
        setManagername(
          res.data.data.first_name +
            " " +
            res.data.data.middle_name +
            " " +
            res.data.data.last_name
        );
        setManagerEmail(res.data.data.email);
        setManagerPhone(res.data.data.mobile);
      } else {
        setManagername("Not assigned");
      }
    });
  };
 
  useEffect(() => {
    if (userData?.role == "user") {
      Promise.all([
        getManagerName(type == 'bp' ? userData?.booking_partner : userData?.relationship_manager )]);
    }

    const tempPermission = {
      [PERMISSION_TYPE.ADMIN]: userPermission(userData, PERMISSION_TYPE.ADMIN),
      [PERMISSION_TYPE.USER]: userPermission(userData, PERMISSION_TYPE.USER),
      [PERMISSION_TYPE.STAFF_OPS]: userPermission(
        userData,
        PERMISSION_TYPE.STAFF_OPS
      ),
      [PERMISSION_TYPE.BOOKING_PARTNER]: userPermission(
        userData,
        PERMISSION_TYPE.BOOKING_PARTNER
      ),
      [PERMISSION_TYPE.STAFF]: userPermission(userData, PERMISSION_TYPE.STAFF),
      [PERMISSION_TYPE.SIDEBAR_DASHBOARD]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_DASHBOARD
      ),
      [PERMISSION_TYPE.SIDEBAR_USER_DATABASE]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_USER_DATABASE
      ),
      [PERMISSION_TYPE.SIDEBAR_STAFF_LIST]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_STAFF_LIST
      ),
      [PERMISSION_TYPE.SIDEBAR_USER_REFFERED]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_USER_REFFERED
      ),
      [PERMISSION_TYPE.SIDEBAR_PAYOUT_GRID]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_PAYOUT_GRID
      ),
      [PERMISSION_TYPE.SIDEBAR_KYC_STATUS]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_KYC_STATUS
      ),
      [PERMISSION_TYPE.SIDEBAR_KYC_ADMIN]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_KYC_ADMIN
      ),
      [PERMISSION_TYPE.SIDEBAR_LOGIN_CODE_LIST]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_LOGIN_CODE_LIST
      ),
      [PERMISSION_TYPE.SIDEBAR_SM_LIST]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_SM_LIST
      ),
      [PERMISSION_TYPE.SIDEBAR_CONNECTOR_LIST]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_CONNECTOR_LIST
      ),
      [PERMISSION_TYPE.SIDEBAR_DISBURSEMENT_DATA]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_DISBURSEMENT_DATA
      ),
      [PERMISSION_TYPE.SIDEBAR_BANK_DISBURSEMENT_DATA]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_BANK_DISBURSEMENT_DATA
      ),
      [PERMISSION_TYPE.SIDEBAR_LOGIN_CREDENTIALS]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_LOGIN_CREDENTIALS
      ),
      [PERMISSION_TYPE.SIDEBAR_INCENTIVE]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_INCENTIVE
      ),
      [PERMISSION_TYPE.SIDEBAR_INCOME_EXPENCE]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_INCOME_EXPENCE
      ),
      [PERMISSION_TYPE.SIDEBAR_BASIC_DETAILS]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_BASIC_DETAILS
      ),
      [PERMISSION_TYPE.SIDEBAR_REVERSE_MIS]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_REVERSE_MIS
      ),
      [PERMISSION_TYPE.SIDEBAR_REVIEW_LOSS]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_REVIEW_LOSS
      ),
      [PERMISSION_TYPE.SIDEBAR_COMPANY_PAYOUT_GRID]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_COMPANY_PAYOUT_GRID
      ),
      [PERMISSION_TYPE.SIDEBAR_BOOKING_PARTNER]: userPermission(
        userData,
        PERMISSION_TYPE.SIDEBAR_BOOKING_PARTNER
      )
    };
    setPermission(tempPermission);
  }, [userData]);

  return (
    <>
      {/* START::SIDEBAR */}
      <aside
        className={`tw-sidebar fixed top-0 text-sm transition-all w-[220px] h-screen overflow-x-hidden overflow-y-auto bg-primary text-light flex flex-col justify-between items-start space-y-[44px] z-[1000] ${
          sidebarToggle ? "lg:left-0 -left-[220px]" : "left-0 lg:-left-[220px]"
        }`}
      >
        {/* START::NAV */}
        <ul className="space-y-[18px]">
          {/* START::LOGO */}
          <li className="p-6 pb-[30px]">
            <img src="../assets/logo.svg" className="h-10" alt="" />
          </li>
          {/* END::LOGO */}

          {/* START::NAVLINK */}

          {/* 
                if(link active)
                    Add class
                        "font-semibold text-white border-secondary"
                    Uncomment img name end with "**-white.svg"
                    Comment img name end with "**-gray.svg"
                else
                    "font-medium border-transparent"
                    Uncomment img name end with "**-gray.svg"
                    Comment img name end with "**-white.svg"
            */}
          {permission[PERMISSION_TYPE.SIDEBAR_DASHBOARD] && (
            <li>
              <Link
                to="/"
                className={`sidebar-nav-link ${
                  location.pathname == "/"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                <span>
                  <img
                    src={
                      location.pathname == "/"
                        ? "../assets/dashboard-white.svg"
                        : "../assets/dashboard-gray.svg"
                    }
                    alt=""
                  />
                </span>
                {/* <span><img src="../assets/dashboard-gray.svg" alt="" /></span> */}
                <span>Dashboard</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_USER_DATABASE] && (
            <li>
              <Link
                to="/userdatabase"
                className={`sidebar-nav-link ${
                  location.pathname.startsWith("/userdatabase")
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/user-group-white.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname.startsWith("/userdatabase")
                        ? "../assets/user-white.svg"
                        : "../assets/user-group-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>User Database</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_STAFF_LIST] && (
            <li>
              <Link
                to="/staff"
                className={`sidebar-nav-link ${
                  location.pathname == "/staff"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/user-group-white.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname == "/staff"
                        ? "../assets/user-white.svg"
                        : "../assets/user-group-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>HR Module</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_BOOKING_PARTNER] && (
            <li>
              <Link
                to="/bookingPartner"
                className={`sidebar-nav-link ${
                  location.pathname == "/bookingPartner"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/user-group-white.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname == "/bookingPartner"
                        ? "../assets/user-white.svg"
                        : "../assets/user-group-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>Booking Partner</span>
              </Link>
            </li>
          )}
          {/* {permission[PERMISSION_TYPE.SIDEBAR_USER_REFFERED] &&
                        <li>
                            <Link to="/userrefferd" className={`sidebar-nav-link ${location.pathname == "/userrefferd" ? "font-semibold text-white border-secondary" : "font-medium border-transparent"}`}>
                                {/* <span><img src="../assets/user-group-white.svg" alt="" /></span> */}
          {/* <span><img src={location.pathname == "/userrefferd" ? "../assets/user-white.svg" : "../assets/user-group-gray.svg"} alt="" /></span>
                                <span>Users Referred</span>
                            </Link>
                        </li> */}
          {/* } */}
          {permission[PERMISSION_TYPE.SIDEBAR_KYC_STATUS] && (
            <li>
              <Link
                to="/kycstatus"
                className={`sidebar-nav-link ${
                  location.pathname == "/kycstatus"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/user-gray.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname == "/kycstatus"
                        ? "../assets/kyc-group-white.svg"
                        : "../assets/user-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>KYC Status</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_KYC_ADMIN] && (
            <li>
              <Link
                to="/kycadmin"
                className={`sidebar-nav-link ${
                  location.pathname == "/kycadmin"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/chart-white.svg" alt="" /></span> */}
                <span>
                  <img src="../assets/chart-gray.svg" alt="" />
                </span>
                <span>Pending for Review</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_INCENTIVE] && (
            <li>
              <Link
                to="/incentive"
                className={`sidebar-nav-link ${
                  location.pathname == "/incentive"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/chart-white.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname == "/incentive"
                        ? "../assets/dis-white.svg"
                        : "../assets/chart-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>Incentive</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_INCOME_EXPENCE] && (
            <li>
              <Link
                to="/miscellaneous"
                className={`sidebar-nav-link ${
                  location.pathname == "/miscellaneous"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/chart-white.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname == "/miscellaneous"
                        ? "../assets/dis-white.svg"
                        : "../assets/chart-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>Income/Expence</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_LOGIN_CODE_LIST] && (
            <li>
              <Link
                to="/logincode"
                className={`sidebar-nav-link ${
                  location.pathname == "/logincode"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/doc-list-white.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname == "/logincode"
                        ? "../assets/doc-list-gray-white.svg"
                        : "../assets/doc-list-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>Login Code List</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_PAYOUT_GRID] && (
            <li>
              <Link
                to="/payoutgrid"
                className={`sidebar-nav-link ${
                  location.pathname == "/payoutgrid"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/payout-white.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname == "/payoutgrid"
                        ? "../assets/pay-out-grid-white.svg"
                        : "../assets/payout-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>Payout Grid</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_COMPANY_PAYOUT_GRID] && (
            <li>
              <Link
                to="/companyPayoutGrid"
                className={`sidebar-nav-link ${
                  location.pathname == "/companyPayoutGrid"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/payout-white.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname == "/companyPayoutGrid"
                        ? "../assets/pay-out-grid-white.svg"
                        : "../assets/payout-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>Company Payout Grid</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_SM_LIST] && (
            <li>
              <Link
                to="/smlist"
                className={`sidebar-nav-link ${
                  location.pathname == "/smlist"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/page-list-white.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname == "/smlist"
                        ? "../assets/sm-list-white.svg"
                        : "../assets/page-list-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>SM List</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_CONNECTOR_LIST] && (
            <li>
              <Link
                to="/connector"
                className={`sidebar-nav-link ${
                  location.pathname == "/connector"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/folder-white.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname == "/connector"
                        ? "../assets/connection-white.svg"
                        : "../assets/folder-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>Connector List</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_DISBURSEMENT_DATA] && (
            <li>
              <Link
                to="/disbursement"
                className={`sidebar-nav-link ${
                  location.pathname == "/disbursement"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/chart-white.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname == "/disbursement"
                        ? "../assets/dis-white.svg"
                        : "../assets/chart-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>Disbursement Data</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_BANK_DISBURSEMENT_DATA] && (
            <li>
              <Link
                to="/bankdisbursement"
                className={`sidebar-nav-link ${
                  location.pathname == "/bankdisbursement"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/chart-white.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname == "/bankdisbursement"
                        ? "../assets/dis-white.svg"
                        : "../assets/chart-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>Bank Disbursement Data</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_REVERSE_MIS] && (
            <li>
              <Link
                to="/reversemis"
                className={`sidebar-nav-link ${
                  location.pathname == "/reversemis"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/chart-white.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname == "/reversemis"
                        ? "../assets/dis-white.svg"
                        : "../assets/chart-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>Reverse MIS</span>
              </Link>
            </li>
          )}
          {permission[PERMISSION_TYPE.SIDEBAR_REVIEW_LOSS] && (
            <li>
              <Link
                to="/reviewloss"
                className={`sidebar-nav-link ${
                  location.pathname == "/reviewloss"
                    ? "font-semibold text-white border-secondary"
                    : "font-medium border-transparent"
                }`}
              >
                {/* <span><img src="../assets/chart-white.svg" alt="" /></span> */}
                <span>
                  <img
                    src={
                      location.pathname == "/reviewloss"
                        ? "../assets/dis-white.svg"
                        : "../assets/chart-gray.svg"
                    }
                    alt=""
                  />
                </span>
                <span>Review Losses</span>
              </Link>
            </li>
          )}

          {/* {permission[PERMISSION_TYPE.SIDEBAR_LOGIN_CREDENTIALS] && */}
          {/* <li> */}
          {/* <Link to="/login_credentials" className={`sidebar-nav-link ${location.pathname == "/login_credentials" ? "font-semibold text-white border-secondary" : "font-medium border-transparent"}`}>
                                {/* <span><img src="../assets/chart-white.svg" alt="" /></span> */}
          {/* <span><img src={location.pathname == "/login_credentials" ? "../assets/dis-white.svg" : "../assets/chart-gray.svg"} alt="" /></span> */}
          {/* <span>Login Credentials</span> */}
          {/* </Link>  */}
          {/* </li> */}
          {/* } */}
          {/* END::NAVLINK */}
        </ul>
        {/* END::NAV */}

        {/* START::BOTTOM NAV */}
        <ul className="space-y-[18px]">
          {/* START::USER INFO */}
          <li className="space-y-3">
            {!permission[PERMISSION_TYPE.ADMIN] &&
              !permission[PERMISSION_TYPE.STAFF] && (
                <>
                  {managername && managerEmail && managerPhone ? (
                    <>
                      <p className="uppercase text-secondary font-semibold text-[15px] leading-[21px] ml-2">
                        {type == 'bp' ? 'Booking Partner' : 'Relationship Manager'}
                      </p>
                      <div className="flex items-center justify-center space-x-3">
                        <div className="w-9 h-9 bg-white p-[1px] rounded-full border border-secondary overflow-hidden">
                          <img src="../assets/user.png" alt="" />
                        </div>
                      </div>
                      <div className="flex items-start space-x-3 ml-2">
                        <ul className="text-white space-y-[2px]">
                          <li className="text-md font-semibold">
                            {managername}
                          </li>
                          <li className="flex items-center space-x-1">
                          <CopyToClipboard text={managerEmail} onCopy={() => toast.success("Copy Successfully.", {
                                    position: "top-right",
                                    theme: "colored"
                                })} >
                            <button aria-label="Filter" ><i className="pi pi-clone"></i></button>
                            </CopyToClipboard>
                            {/* <span>
                              <img src="../assets/email-white.svg" alt="" />
                            </span> */}
                            <span className="opacity-70 text-[12px] text-wrap">
                              {managerEmail}
                            </span>                            
                          </li>
                          <li className="flex items-center space-x-1">
                          <CopyToClipboard text={managerPhone} onCopy={() => toast.success("Copy Successfully.", {
                                    position: "top-right",
                                    theme: "colored"
                                })} >
                            <button aria-label="Filter" ><i className="pi pi-clone"></i></button>
                            </CopyToClipboard>
                            {/* <span>
                              <img src="../assets/phone-white.svg" alt="" />
                            </span> */}
                            <span className="opacity-70 text-[12px]">
                              {managerPhone}
                            </span>
                            </li>
                        </ul>
                      </div>
                    </>
                  ) : null}
                </>
              )}
          </li>
          {/* END::USER INFO */}
        </ul>
        {/* END::BOTTOM NAV */}
      </aside>
      {/* END::SIDEBAR */}
    </>
  );
}

export default Sidebar;
